import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Connection, ConnectionStop, ConnectionTime, Time } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../../../shared/system/translate';
import dayjs from '../../../../shared/system/day';
import { getTimeFromUTCDate, getUtcOffsetString } from '../../../../shared/util/date';

const getDelayString = (ISODelay) => {
    if (!ISODelay) {
        return '';
    }

    const delayInSeconds = dayjs.duration(ISODelay).asSeconds();

    if (delayInSeconds === 0) {
        return '';
    }

    if (Math.abs(delayInSeconds) < 60) {
        return delayInSeconds < 0 ? '(<-1 min)' : '(<1 min)';
    } else {
        const delayInMinutes = Math.floor(dayjs.duration(ISODelay).asMinutes());
        return `(${delayInMinutes} min)`;
    }
};

const getIsArrivalOrDepartureDelayed = (arrivalISODelay, departureISODelay) => {
    const isArrivalDelay = arrivalISODelay
        ? dayjs.duration(arrivalISODelay).asSeconds() !== 0
        : false;
    const isDepartureDelay = departureISODelay
        ? dayjs.duration(departureISODelay).asSeconds() !== 0
        : false;

    return !!(isArrivalDelay || isDepartureDelay);
};

const isDelayed = (delayTime) => {
    return delayTime ? dayjs.duration(delayTime).asSeconds() !== 0 : false;
};

export default function DelayAdded({
    item: {
        details: { delays },
        type,
    },
}) {
    const { translate } = useContext(TranslateContext);

    return (
        <div style={{ maxWidth: '600px', marginBottom: '15px' }}>
            {type === 'MANUAL_DELAY' ? (
                <p>{translate('manual-delay-entered')}</p>
            ) : (
                <p>
                    <strong>{translate('delay_data_provided')}</strong>
                    {translate('for_stations_with_time_changed')}
                </p>
            )}

            <Connection>
                {delays.map(
                    ({
                        station,
                        delayedDeparture,
                        departureDelay,
                        scheduledDeparture,
                        delayedArrival,
                        arrivalDelay,
                        scheduledArrival,
                    }) =>
                        getIsArrivalOrDepartureDelayed(arrivalDelay, departureDelay) ? (
                            <ConnectionStop
                                key={station.name}
                                station={`(${station.code})-${station.name}${
                                    station.timezone
                                        ? ` (${getUtcOffsetString(station.timezone)})`
                                        : ''
                                }`}
                                time={
                                    <ConnectionTime
                                        style={{ textAlign: 'left', width: '170px' }}
                                        time={
                                            <>
                                                {isDelayed(arrivalDelay) && (
                                                    <Time
                                                        style={{ marginRight: '10px' }}
                                                        label="Old arrival time:"
                                                        Elem="del"
                                                        dateTime={scheduledArrival}
                                                    >
                                                        {getTimeFromUTCDate(scheduledArrival)}
                                                    </Time>
                                                )}

                                                <Time
                                                    style={
                                                        isDelayed(arrivalDelay)
                                                            ? { color: '#ff5704' }
                                                            : {}
                                                    }
                                                    label="New arrival time:"
                                                    Elem="ins"
                                                    dateTime={delayedArrival}
                                                >
                                                    {getTimeFromUTCDate(delayedArrival)}{' '}
                                                    {getDelayString(arrivalDelay, delayedArrival)}
                                                </Time>
                                            </>
                                        }
                                        secondTime={
                                            <>
                                                {isDelayed(departureDelay) && (
                                                    <Time
                                                        style={{ marginRight: '10px' }}
                                                        label="Old departure time:"
                                                        Elem="del"
                                                        dateTime={scheduledDeparture}
                                                    >
                                                        {getTimeFromUTCDate(scheduledDeparture)}
                                                    </Time>
                                                )}
                                                <Time
                                                    style={
                                                        isDelayed(departureDelay)
                                                            ? { color: '#ff5704' }
                                                            : {}
                                                    }
                                                    label="New departure time:"
                                                    Elem="ins"
                                                    dateTime={delayedDeparture}
                                                >
                                                    {getTimeFromUTCDate(delayedDeparture)}{' '}
                                                    {getDelayString(
                                                        departureDelay,
                                                        delayedDeparture
                                                    )}
                                                </Time>
                                            </>
                                        }
                                    />
                                }
                            />
                        ) : null
                )}
            </Connection>
        </div>
    );
}

DelayAdded.propTypes = {
    item: PropTypes.object.isRequired,
};
