import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslateContext } from '../../../../shared/system/translate';

export default function PaxStatus({ item }) {
    const { translate } = useContext(TranslateContext);
    const ticketId = item?.details?.ticketId || translate('checkin.ticket-not-found');
    let status;
    let info;

    switch (item.type) {
        case 'PAX_ABSENT':
            status = translate('checkin.passenger-absent');
            info = '';
            break;
        case 'PAX_CHECKED':
            status = translate('checkin.passenger-checked');
            info = '(' + (item.details.isManual ? translate('checkin.manual') : item.details.source) + ')'
            break;
        case 'PAX_UNCHECKED':
            status = translate('checkin.passenger-unchecked');
            info = '';
    }

    return (
        <div>
            {translate('checkin.passenger-status')} <strong>{status}</strong>{' '}
            {info} {' '}
            {translate('for_ticket')} <strong>{ticketId}</strong>
        </div>
    );
}

PaxStatus.propTypes = {
    item: PropTypes.object.isRequired,
};
